const config = {
    apiUrl: 'https://ieelifts.in/api',
    documentUrl: 'https://ieelifts.in/api/document',
    // apiUrl: 'http://localhost:8000/api',
    // documentUrl: 'http://localhost:8000/api/document',
    // apiUrl: 'http://192.168.29.44:8000/api',
    // documentUrl: 'http://192.168.29.44:8000/api/document'
    // apiUrl: 'https://iee-admin-pannel-backend.onrender.com/api',
    // documentUrl: 'https://iee-admin-pannel-backend.onrender.com/api/document'
};

export default config;
