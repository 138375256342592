export const Engineers = [
    {
        EnggId: 1111,
        EnggPassword: 2323223,
        EnggName: "Jack Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Offline",
        message: "I am on a break",
        messageCount: 2
    },
    {
        EnggId: 1112,
        EnggPassword: 2323223,
        EnggName: "Jack Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "On-break",
        message: "",
        messageCount: 0
    },
    {
        EnggId: 1113,
        EnggPassword: 2323223,
        EnggName: "karan Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Working",
        message: "",
        messageCount: 0
    },
    {
        EnggId: 1114,
        EnggPassword: 2323223,
        EnggName: "Jack Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Leave",
        message: "",
        messageCount: 0
    },
    {
        EnggId: 1115,
        EnggPassword: 2323223,
        EnggName: "Jack Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Working",
        message: "",
        messageCount: 0
    },
    {
        EnggId: 1116,
        EnggPassword: 2323223,
        EnggName: "Jack Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Working",
        message: "",
        messageCount: 0
    },
    {
        EnggId: 1117,
        EnggPassword: 2323223,
        EnggName: "Jack Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Working",
        message: "",
        messageCount: 0
    },
    {
        EnggId: 1118,
        EnggPassword: 2323223,
        EnggName: "Jack Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Working",
        message: "",
        messageCount: 0
    },
    {
        EnggId: 1119,
        EnggPassword: 2323223,
        EnggName: "Jack Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Working",
        message: "",
        messageCount: 0
    },
    {
        EnggId: 1120,
        EnggPassword: 2323223,
        EnggName: "Jack Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Working",
        message: "haan vail ki haal chal ne, sab vadiya ",
        messageCount: 2
    },
    {
        EnggId: 1121,
        EnggPassword: 2323223,
        EnggName: "Jack Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Working",
        message: "",
        messageCount: 0
    },
    {
        EnggId: 1122,
        EnggPassword: 2323223,
        EnggName: "Jack Smith",
        PhoneNumber: 61409123456,
        EnggAddress: "14 Maple Street, Sydney, NSW 2000",
        EnggPhoto: "https://images.unsplash.com/photo-1590086782957-93c06ef21604?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Working",
        message: "",
        messageCount: 0
    }
];
